<template>
  <div class="language">
    <div class="row">
      <div class="col inner py-3">
        <h3 class="text-center mb-3">{{ $t('data.changeLanguage') }}</h3>
        <div class="form-floating mb-3">
          <select class="form-select" id="floatingSelect" v-model="formData.language">
            <option v-for="(language, key) in languages" :key="key" :value="key">{{language}}</option>
          </select>
          <label for="floatingSelect">{{ $t('common.language') }}</label>
        </div>
        <div class="text-center">
          <button class="btn btn-primary" @click="submit()"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> {{ $t('data.changeLanguage') }}</button>
        </div>
        <div class="menu text-center mt-3">
          <span @click="$emit('close')">{{ $t('common.abort') }}</span>
        </div>
      </div></div>
  </div>
</template>

<script>
import {setI18nLanguage} from "@/i18n";
import {i18n} from "@/main";

export default {
  name: "HomeMyDataLanguage",
  mounted() {
    this.formData.language= this.$auth.user().agilea.language;
  },
  methods:{
    submit(){
      setI18nLanguage(i18n, this.formData.language);
      let msg  = this.$t('data.successLanguageChange');

      this.axios.post( "myLogin/updateLanguage", {language: this.formData.language}).then(res => {
        if(res.data.success == true){

          this.$auth.fetch().then(() => {
              this.$toast.success(msg);
          });

          this.$emit('close');
          this.loading = false;
        }
        else{
          let msg = this.$toast.error(this.$t('error.retry'));
          if(res.data.message){
            msg = res.data.message;
          }
          this.$toast.error(msg);
          this.loading = false;
        }
      }).catch(error => {
        this.errors = error.response.data.errors || {};

        if(error.response.status != 422){
          this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        }

        this.loading = false;
      });


    }
  },
  data(){
    return {
      loading: false,
      formData:{
        language: null,
      },
      languages: {
        'de' : "Deutsch",
        'en' : "English",
        'fr' : "Français",
        'it' : "Italiano",
      }
    };
  }
}
</script>

<style scoped>

</style>
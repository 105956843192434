<template>
  <div class="additionEdit">
    <div class="row">
      <div class="col inner py-3">
        <addition-edit @close="$emit('close')" abortBtn="true" headline="true"></addition-edit>
      </div>
    </div>
  </div>
</template>

<script>
import AdditionEdit from "@/pages/additions/AdditionEdit";

export default {
  name: "HomeContractAddition",
  components: {AdditionEdit},
  mounted() {
    this.$gtag.pageview('/Zusatz-bearbeiten');
  }
}
</script>

<style scoped>

</style>
<template>
  <div id="allInOneUpgrade" class="my-5">
    <div class="row align-content-center overflow-hidden">
      <div class="col-xs-12 col-md-4 col-lg-6" style=" min-height: 150px;">
        <div class="h-100" style="background: url(/img/additions/HydroMassage.jpg) no-repeat center; background-size: cover;">

        </div>
      </div>
      <div class="col p-3 d-flex flex-column">
        <div class="flex-grow-1">
          <h3 class="text-primary text-uppercase" v-html="$t('allInOneHydroUpgrade.headline')"></h3>
          <p>{{$t('allInOneHydroUpgrade.greetings', {name: $auth.user().agilea.firstName})}}</p>
          <template v-if="contract.cLength == 24">
            <div v-html="$t('allInOneHydroUpgrade.textContract24')"></div>
          </template>
          <template v-else>
            <div v-html="$t('allInOneHydroUpgrade.text')"></div>
          </template>
        </div>
        <div class="text-center mt-3">
          <button class="btn btn-primary" @click="submit()" :disabled="loading"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>{{ $t('allInOneHydroUpgrade.action') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeAdditionHMUpgrade",
  data(){
    return {
      loading: false,
    }
  },
  computed: {
    contract() {
      return this.$store.state.contract.contract;
    },
  },
  methods: {
    submit(){
      this.loading = true;
      this.axios.post('/myLogin/aboUpgradeAllInOneHydoMassage').then( res => {
          if (res.data.success == true) {
              this.$toast.success(this.$t('allInOneHydroUpgrade.success'));
              this.$contract.fetch();
          } else {
              this.$toast.error(this.$t('error.retry'));
              this.loading = false;
          }
      }).catch(error => {
        this.errors = error.response.data.errors || {};

        if(error.response.status != 422){
          this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        }

        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="ContractTermination">
    <div class="row">
      <div class="col inner py-3">
        <h3 class="text-center mb-3">{{ $t('contract.termination.headline') }}</h3>

        <p>{{ $t('contract.termination.intro') }}</p>

        <div class="form-floating mb-3">
          <select class="form-select" id="mainReason" style="max-width: 100%; width: 100%;" v-model="formData.mainReason">
            <option v-for="reason in shuffledReasons" :key="reason" :value="reason">{{reason.reason}}</option>
          </select>
          <label for="mainReason">{{ $t('contract.termination.reason') }}:</label>
        </div>
        <div class="form-floating mb-3" v-if="formData.mainReason && formData.mainReason.children.length">
          <select class="form-select" id="sReason" style="max-width: 100%; width: 100%;" v-model="formData.subReason">
            <option v-for="sReason in shuffledSubreasons" :value="sReason" :key="sReason">{{sReason.reason}}</option>
          </select>
          <label for="sReason">{{ $t('contract.termination.reasonDetail') }}:</label>
        </div>

        <CFormFloating v-if="(formData.subReason && formData.subReason.variable == 1) || (formData.mainReason && formData.mainReason.variable == 1)" class="mb-3">
          <CFormInput type="text" id="explication" placeholder="????" v-model="formData.explication"/>
          <CFormLabel for="explication">{{ $t('contract.termination.reasonExplain') }}:</CFormLabel>
        </CFormFloating>

        <div class="text-center mb-3" v-if="contract.optionalEndDate">
          <button class="btn btn-primary" :disabled="!isValid()" @click="endOptionalDate()">{{ $t('contract.termination.terminateToDate', {date: optionalEndDate}) }}</button>
        </div>

        <div class="text-center">
            <button class="btn btn-primary" :disabled="!isValid()" @click="endRegular()">{{ $t('contract.termination.terminateToDate', {date: regularEndDate}) }}</button>
        </div>

        <div class="menu text-center mt-3">
          <span @click="$emit('close')">{{ $t('common.abort') }}</span>
        </div>
      </div>
    </div>
    <CModal :visible="showConfirm" alignment="center" @close="showConfirm=false">
        <CModalHeader>
          <CModalTitle>Kündigungsbestätigung</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>
            Sicher, dass du kündigen möchtest?
          </p>
        </CModalBody>
         <CModalFooter class="text-end">
              <button class="btn btn-dark" @click="showConfirm=false" :disabled="loading">Nein, doch nicht.</button>
              <button class="btn btn-default" @click="submit()" :disabled="loading"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Ja, ich möchte.</button>
         </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import { CFormFloating, CFormInput,  CFormLabel, CModal, CModalFooter, CModalBody, CModalHeader, CModalTitle} from '@coreui/bootstrap-vue';
import moment from "moment";

export default {
  name: "HomeMyContractEnd",
  components: {
    CModalTitle,
    CModalHeader,
    CFormFloating,CFormInput,  CFormLabel, CModal, CModalFooter, CModalBody,
  },
  watch:{
    '$i18n.locale'(){
      this.load();
    }
  },
  computed:{
    optionalEndDate(){
      if(!this.contract) { return null; }
      if(!this.contract.optionalEndDate) { return null; }

      return moment(this.contract.optionalEndDate).format('DD.MM.YYYY');
    },
    regularEndDate(){
      if(!this.contract) { return null; }
      if(!this.contract.accessEndDate) { return null; }

      return moment(this.contract.accessEndDate).format('DD.MM.YYYY');
    },
    contract () {
      return this.$store.state.contract.contract;
    },
    shuffledReasons(){
      if(!this.reasons) return this.reasons;

      let currentIndex = this.reasons.length,  randomIndex;
      let array = this.reasons;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    shuffledSubreasons(){
      if(!this.formData.mainReason) return this.formData.mainReason;

      let currentIndex = this.formData.mainReason.children.length,  randomIndex;
      let array = this.formData.mainReason.children;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    }
  },
  data() {
    return{
        showConfirm: false,
        reasons: null,
        formData: {
          mainReason: null,
          subReason: null,
          explication: '',
          optional: false,
        },
        loading: false,
    }
  },
  mounted(){
    this.$gtag.pageview('/Vertragkuendingen');
    this.load();
  },
  methods:{
    endOptionalDate(){
      this.formData.optional = true;
      this.showConfirm=true;
    },
    endRegular(){
      this.formData.optional = false;
      this.showConfirm=true;
    },
    submit(){
      this.loading = true;

      this.axios.post("myLogin/contractTermination", this.formData).then(res => {
        if(res.data.success == true){
          this.$contract.fetch();
          this.$toast.success('Deine Kündigung ist erfolgreich bei uns eingegangen.');
          this.formData = {
            mainReason: null,
            subReason: null,
            explication: '',
          };
          this.$emit('close');
        }
        else{
          this.$toast.error('Leider ist ein unbekannter Fehler aufgetreten. Versuche es bitte später noch einmal.');
        }
        this.showConfirm = false;
        this.loading = false;
      }).catch(error => {
        this.$toast.error('Ein unbekannter Fehler ist aufgetreten!<br><b>Error: </b>' + error.response.status.toString() );
        this.loading = false;
      });
    },
    load(){
        this.axios.get("myLogin/terminationReasons").then(res => {
          this.formData = {
            mainReason: null,
            subReason: null,
            explication: '',
          };

          this.reasons = res.data.reasons;
        });
    },
    isValid(){
      if(!this.formData.mainReason){
        return false;
      }
      if(this.formData.mainReason.children.length > 0 && !this.formData.subReason){
        return false;
      }
      if(this.formData.mainReason.variable == 1 && !this.formData.explication){
        return false;
      }
      if(this.formData.subReason && this.formData.subReason.variable == 1 && !this.formData.explication){
        return false;
      }
      return true;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="ContractTermination">
    <div class="row">
      <div class="col inner py-3">
        <h3 class="text-center mb-3">{{ $t('contract.revoke.headline') }}</h3>

        <p class="mt-5" v-html="$t('contract.revoke.intro1', {contractEnd:contractEnd, contractAccessEnd: contractAccessEnd })"></p>
        <p class="mb-5">
          {{$t('contract.revoke.intro2')}}
        </p>

        <div class="text-center">
          <button class="btn btn-primary" @click="showConfirm=true">{{$t('contract.revoke.action')}}</button>
        </div>

        <div class="menu text-center mt-3">
          <span @click="$emit('close')">{{$t('common.abort')}}</span>
        </div>
      </div>
    </div>
    <CModal :visible="showConfirm" alignment="center" @close="showConfirm=false">
      <CModalHeader>
        <CModalTitle>{{$t('contract.revoke.messages.confirm.headline')}}</CModalTitle>
      </CModalHeader>
      <CModalBody v-html="$t('contract.revoke.messages.confirm.message')">
      </CModalBody>
      <CModalFooter class="text-end">
        <div class="container-fluid">
        <div class="row">
          <div class="col-md-7 order-md-1">
            <button class="btn btn-primary" @click="submit()" :disabled="loading"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> {{ $t('contract.revoke.messages.confirm.confirm') }}</button>
          </div>
          <div class="col-md-5">
            <button class="btn btn-default" @click="showConfirm=false" :disabled="loading">{{ $t('contract.revoke.messages.confirm.abort') }}</button>
          </div>
        </div>
        </div>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import moment from "moment";
import {
  CModal, CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from "@coreui/bootstrap-vue";

export default {
  name: "HomeMyContractRevoke",
  components: {
    CModalTitle,
    CModalHeader, CModal, CModalFooter, CModalBody,
  },
  mounted() {
    this.$gtag.pageview('/Kuendigungzurueckziehen');
  },
  methods:{
    submit(){
      this.loading = true;

      this.axios.post("myLogin/contractRevokeTermination", this.formData).then(res => {
        if(res.data.success == true){
          this.$contract.fetch();
          this.$toast.success(this.$t('contract.revoke.success'));
          this.formData = {
            mainReason: null,
            subReason: null,
            explication: '',
          };
          this.$emit('close');
          this.loading = false;
          this.showConfirm = false;
        }
        else{
          this.$toast.error(this.$t('error.retry'));
          this.loading = false;
        }
      }).catch(error => {
        this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        this.loading = false;
      });
    },
  },
  data(){
    return {
        showConfirm: false,
        loading: false,
    }
  },
  computed:{
    contract () {
      return this.$store.state.contract.contract;
    },
    contractEnd(){
      if(!this.contract) { return "-"; }

      return moment(this.contract.contractEnd).format('DD.MM.YYYY');
    },
    contractAccessEnd(){
      if(!this.contract) { return "-"; }

      return moment(this.contract.accessEndDate).format('DD.MM.YYYY');
    },

    contractBegin(){
      if(!this.contract) { return "-"; }

      return moment(this.contract.contractBegin).format('DD.MM.YYYY');
    },
    contractAccessBegin(){
      if(!this.contract) { return "-"; }

      return moment(this.contract.accessBeginDate).format('DD.MM.YYYY');
    },
  },
}
</script>

<style scoped>

</style>
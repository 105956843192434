<template>
        <div class="container-fluid h-100">
            <div class="row h-100 align-items-center">
              <div id="loginImage" class="d-none d-md-flex col-6 offset-lg-1 order-1 h-100"></div>
                <div class="col-12 col-md-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-2 mb-5 pb-5">
                  <div class="dropdown float-end">
                    <span class="dropdown-toggle text-uppercase" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fa-solid fa-globe"></i> {{ $i18n.locale }}
                    </span>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1" style="min-width: 5rem;">
                      <li><a class="dropdown-item" :class="[$i18n.locale == 'de' ? 'active' : '']" href="#" @click="setLocale('de')">DE</a></li>
                      <li><a class="dropdown-item" :class="[$i18n.locale == 'en' ? 'active' : '']" href="#" @click="setLocale('en')">EN</a></li>
                      <li><a class="dropdown-item" :class="[$i18n.locale == 'fr' ? 'active' : '']" href="#" @click="setLocale('fr')">FR</a></li>
                      <li><a class="dropdown-item" :class="[$i18n.locale == 'it' ? 'active' : '']" href="#" @click="setLocale('it')">IT</a></li>
                    </ul>
                  </div>
                  <h1 style="line-height: 1.1em;" v-html="$t('login.welcome')"></h1>
                  <p class="mb-5">{{ $t('login.welcome_subtext')}}</p>

                  <CFormFloating class="mb-3">
                    <CFormInput type="email" ref="email" id="email" placeholder="example@example.ch" v-model="formData.email" @keyup.enter="nextField()"/>
                    <CFormLabel for="email" ><i class="fa-solid fa-envelope me-3  ms-1"></i> {{ $t('login.email')}}</CFormLabel>
                  </CFormFloating>
                  <CFormFloating>
                    <CFormInput type="password" ref="password" id="password" placeholder="*******" v-model="formData.password" @keyup.enter="nextField()"/>
                    <CFormLabel for="password" ><i class="fa-solid fa-key me-3  ms-1"></i> {{ $t('login.password')}}</CFormLabel>
                  </CFormFloating>
                  <div class="mb-3">
                    <router-link :to="{ name: 'forgetPassword'}">{{ $t('login.passwordForgotten')}}</router-link>
                  </div>
                  <div class="d-grid gap-2">
                    <button class="btn btn-primary btn-lg " @click="login" :disabled="loading"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>{{ $t('login.login')}}</button>
                  </div>
                  <i18n-t keypath="login.needRegistration" tag="div">
                    <template v-slot:url>
                        <router-link :to="{ name: 'register'}">{{ $t('login.registerNow')}}</router-link>
                    </template>
                  </i18n-t>


                  <div class="alert alert-warning mt-5" v-html="$t('login.exerp')"></div>
                </div>

            </div>
        </div>

</template>


<script>

import { CFormFloating, CFormInput,  CFormLabel} from '@coreui/bootstrap-vue';
import {setI18nLanguage} from "@/i18n";
import {i18n} from "@/main";

export default {
  name: "LoginForm",
  components: {
    CFormFloating,CFormInput,  CFormLabel
  },
  data(){
    return {
        loading: false,
        formData: {
          email: "florian.stern@agilea.de",
          password: "basefit24!",
          device: 'browser',
        },
        has_error: false,
      };
  },
  mounted(){
  },
  methods: {
    setLocale(locale){
      setI18nLanguage(i18n, locale);
    },
    nextField(){
        if(!this.formData.email){
          this.$refs.email.$el.focus();
          return;
        }
        if(!this.formData.password){
          this.$refs.password.$el.focus();
          return;
        }

        this.login();
    },
    login(){
      let redirect = this.$auth.redirect();
      let app = this;

      this.loading = true;

      this.$auth.login( {
        data: {
          email: this.formData.email,
          password: this.formData.password
        },
        rememberMe: false,
        fetchUser: false,
      }).then(response => {
        app.has_error = false;

        this.loading = false;

        if(response.data.admin && response.data.admin.admin === true){
          this.$store.commit('setAdminStatus', response.data.admin.adminToken);
        }

        this.$gtag.event('login', { 'event_category': 'Login', method: 'EMail-Address' });

        this.$auth.fetch().then(() => {
            this.$emit("login");
        });
        this.$contract.fetch();
        this.$router.push({name: 'dashboard'});
      }, (res) => {
        this.loading = false;

        if(res.response.data.error_code == 200){
          this.$toast.warning(this.$t('login.exerp'));
        }
        else{
          this.$toast.error(this.$t('login.error'));
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div id="myKK" class="widget" :class="{active: isActive()}">
    <div class="hr">
      <span>{{ $t('kk.headline') }}</span>
    </div>

    <div class="">
      <ul class="dataListing" v-if="!isActive()">
        <li>
          <div class="field">{{ $t('kk.intro') }}</div>
          <div class="alert alert-danger mt-3" v-if="$store.state.contract.contract.isOverdueBills">
            {{ $t('kk.openClaims') }}
          </div>
        </li>
      </ul>
    </div>

    <div v-if="isActive()">
        <div class="row">
          <div class="col inner py-3">
              <h3 class="text-center mb-3" v-html="$t('kk.qualitopHeadline')"></h3>

            <p>
              <strong>{{ $t('kk.qualitopSubheadline') }}</strong>
            </p>
            <p>
              <img src="../assets/Qualitop-Logo.png" class="float-end ms-3 mb-3">{{ $t('kk.qualitopText') }}
            </p>

            <div class="my-5 ps-3" v-if="loadingYears">
              <div class="dot-pulse"></div>
              {{ $t('kk.loadData') }}
            </div>
            <div v-else>
            <div v-if="!availableYears || availableYears.length == 0">

                <div class="alert alert-info mt-3">
                  {{ $t('kk.notFound') }}
                </div>

                <div class="menu text-center mt-3">
                  <span @click="active = false">{{ $t('common.abort') }}</span>
                </div>
            </div>
            <div v-else>
                <p>
                  {{ $t('kk.fillOut') }}
                </p>

                <h4 class="mt-5">{{ $t('kk.healthInsurance') }}</h4>
                <CFormFloating class="mb-3">
                  <CFormInput type="text" id="Krankenversicherung" placeholder="Krankenversicherung" v-model="formData.Krankenversicherung"/>
                  <CFormLabel for="Krankenversicherung">{{ $t('kk.healthInsurance') }}</CFormLabel>
                </CFormFloating>
                <CFormFloating class="mb-3">
                  <CFormInput type="text" id="Zusatzversicherung" placeholder="Zusatzversicherung" v-model="formData.Zusatzversicherung"/>
                  <CFormLabel for="Zusatzversicherung">{{ $t('kk.additionalInsurance') }}</CFormLabel>
                </CFormFloating>
                <CFormFloating class="mb-3">
                  <CFormInput type="text" id="Versichertennummer" placeholder="Versichertennummer" v-model="formData.Versichertennummer"/>
                  <CFormLabel for="Versichertennummer">{{ $t('kk.insuranceNumber') }}</CFormLabel>
                </CFormFloating>



                <div class="form-floating">
                  <select class="form-select" id="floatingSelect" aria-label="Floating label select example" v-model="formData.year">
                    <option v-for="year in availableYears" :key="year">{{year}}</option>
                  </select>
                  <label for="floatingSelect">{{ $t('kk.chooseYear') }}</label>
                </div>


              <div class="border mt-3 p-3 bg-dark position-relative" v-if="$root.admin">
                <div v-if="loadingData" class="loading  w-100 h-100 top-0 start-0" style="z-index: 15; position: absolute;"></div>

                <b class="text-white d-block mb-3">Erweiterte Admin-Optionen</b>

                <div class="row">
                  <div class="col col-md-5">
                    <CurrencyInput v-model.lazy="formData.beitrag"/>
                  </div>
                  <!--<div class="col">
                    <CFormFloating class="">
                      <CFormInput type="text" id="EMail" placeholder="E-Mail-Adresse" v-model="formData.email"/>
                      <CFormLabel for="EMail">E-Mail-Adresse (optional)</CFormLabel>
                    </CFormFloating>
                  </div>-->
                </div>
                <div class="row">
                    <div class="col">
                      <div class="form-floating mb-3">
                        <select class="form-select" id="floatingSelect"  v-model="formData.contractType" aria-label="Floating label select example">
                          <option :value="null">Bitte wählen</option>
                          <option :value="1">Monats- / 10-er Abo</option>
                          <option :value="2">Halbjahres Vertrag</option>
                          <option :value="3">1 Jahres Vertrag</option>
                          <option :value="4">2 Jahres Vertrag</option>
                          <option :value="5">sonstiges Vertrag</option>
                        </select>
                        <label for="floatingSelect">Vertragsart</label>
                      </div>
                    </div>
                    <div class="col-8" v-if="formData.contractType == 5">
                      <div class="input-group">

                        <CFormFloating class="" style="flex: 1 1 auto; width: 1%; min-width: 0;">
                          <CFormInput type="text" id="contractTime" placeholder="E-Mail-Adresse" class="text-end" v-model="formData.contractTime"/>
                          <CFormLabel for="contractTime">Vertragsdauer</CFormLabel>
                        </CFormFloating>
                        <span class="input-group-text">Jahre</span>
                      </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label class="form-label text-white">Abo-Start:</label>
                      <datepicker class="form-control" ref="dateFrom"  language="de" :locale="de"
                                  inputFormat="dd.MM.yyyy"
                                  v-model="formData.aboFrom"></datepicker>
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label class="form-label text-white">Abo-Ende:</label>
                      <datepicker class="form-control" ref="dateFrom"  language="de" :locale="de"
                                  inputFormat="dd.MM.yyyy"
                                  v-model="formData.aboTo"></datepicker>
                    </div>
                  </div>
                </div>
              </div>

                <p class="mb-3 text-primary" style="font-size: 0.7em; font-weight: bold;">
                  {{ $t('kk.calculationHint') }}
                </p>
                <div class="text-center">
                  <button class="btn btn-primary" :disabled="isDisabled()" @click="submit()"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> {{ $t('kk.generateForm') }}</button>
                </div>
                <div class="menu text-center mt-3">
                  <span @click="active = false">{{ $t('common.abort') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="menu mt-3" @click="activate()" v-if="!isActive() && !$store.state.contract.contract.isOverdueBills">
      <span>{{ $t('common.apply') }}</span>
    </div>
  </div>
</template>

<script>
import { CFormFloating, CFormInput,  CFormLabel} from '@coreui/bootstrap-vue';
import CurrencyInput from "@/components/CurrencyInput.vue";
import Datepicker from "vue3-datepicker";

import {de} from 'date-fns/locale';
import 'moment/locale/de';
import moment from "moment";

export default {
  name: "HomeMyKK",
  components: {
    Datepicker,
    CurrencyInput,
    CFormFloating,CFormInput, CFormLabel
  },
  setup() {
    return {
      de,
    };
  },
  watch: {
    'formData.year'(value){
        if(! this.$root.admin) return;

        let url = "myLogin/qualitopData";

        this.loadingData = true;
        this.axios.post(url, {year: value}).then(res => {
            this.formData.beitrag = res.data.amount;
            this.formData.aboFrom = moment(res.data.aboStart).toDate();
            this.formData.aboTo = moment(res.data.aboEnd).toDate();
            //this.formData.aboTo = res.data.aboEnd;
            this.formData.contractType = res.data.contractType;
            this.formData.contractTime = 3;
            this.formData.email = res.data.email;
            this.loadingData = false;
        });
    }
  },
  data(){
    return {
      active: false,
      formData: {
        Krankenversicherung: '',
        Zusatzversicherung: '',
        Versichertennummer: '',
        year: null,
        email: '',
        beitrag: 0.00,
        contractType: null,
        contractTime: null,
        aboFrom: null,
        aboTo: null,
        adminToken: false,
      },
      availableYears: [],
      loading: false,
      loadingYears: false,
      loadingData: false,
    }
  },
  mounted() {
    //this.loadYears();
    //this.$gtag.pageview('/KK-Antrag');
  },

  methods:{
    minStartDate(){
      return moment().startOf('month').toDate();
    },
    isDisabled(){
      if(this.loading) return false;

      if(!this.formData.Krankenversicherung){
        return true;
      }
      if(!this.formData.Zusatzversicherung){
        return true;
      }
      if(!this.formData.Versichertennummer){
        return true;
      }
      if(!this.formData.year){
        return true;
      }

      return false;
    },
    isActive(){
      return this.active;
    },
    submit(){
        this.loading = true;

        if(this.$root.admin){
          this.formData.adminToken = this.$root.adminToken;
        }

        this.axios.post("myLogin/qualitop", this.formData).then(res => {
            if(res.data.success == true){
               this.$toast.success(this.$t('kk.success'));
               this.active = false;
               this.$gtag.event('click', {
                  'event_category': 'KK-Antrag',
                  'event_label': this.formData.year,
               });
            }
            else{
              this.$toast.error(this.$t('error.retry'));
            }

            this.loading = false;
        }).catch(error => {
          this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
          this.loading = false;
        });
    },
    activate(){
      this.loadYears();
      this.$gtag.pageview('/KK-Antrag');
      this.active = true;
    },
    loadYears(){
      this.loadingYears = true;
      this.axios.get('myLogin/contracts/paidYears').then(res => {
          this.availableYears = res.data.years;
          //this.availableYears = [];
          this.loadingYears = false;
      }).finally(() => {
        this.loadingYears = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
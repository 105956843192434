<template>
  <div class="alias">
    <div class="row">
      <div class="col inner py-3">
        <h3 class="text-center mb-3">Zahlungsdaten aktualisieren</h3>

        <div class="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3" >
          <ul class="payment">
            <li><input name="payment" id="paypal" value="paypal" type="radio" v-model="formData.paymentMethod"><label for="paypal"><img src="https://puregym.swiss/wp-content/themes/basefit/img/payments/paypal_logo.png" srcset="https://basefit.ch/wp-content/themes/basefit/img/payments/paypal_logo_2x.png 2x"><span></span></label></li>
            <li><input name="payment" id="visa" value="visa" type="radio" v-model="formData.paymentMethod"><label for="visa"><img src="https://puregym.swiss/wp-content/themes/basefit/img/payments/visa.png" srcset="https://basefit.ch/wp-content/themes/basefit/img/payments/visa_2x.png 2x"> <span></span></label></li>
            <li><input name="payment" id="mastercard" value="mastercard" type="radio" v-model="formData.paymentMethod"><label for="mastercard"><img src="https://puregym.swiss/wp-content/themes/basefit/img/payments/mastercard.png" srcset="https://basefit.ch/wp-content/themes/basefit/img/payments/mastercard_2x.png 2x"> <span></span></label></li>
            <!--<li><input name="payment" id="postfinance" value="postfinancecard" type="radio" v-model="formData.paymentMethod"><label for="postfinance"><img src="https://puregym.swiss/wp-content/themes/basefit/img/payments/postfinance_card_chf.png" srcset="https://basefit.ch/wp-content/themes/basefit/img/payments/postfinance_card_chf_2x.png 2x"> <span></span></label></li>-->
          </ul>

          <form id="paymentForm" method="post" class="testmode" action="https://secure.payengine.de/ncol/prod/orderstandard_utf8.asp"></form>
        </div>

        <p style="color: #e60000; font-size: 0.8rem;">Zur Verifizierung deines Kontos werden Dir CHF 0.01 abgebucht. Diesen Betrag gleichen wir aus. Es entstehen keine Zusatzkosten!</p>

        <div class="text-center">
          <button class="btn btn-primary" @click="submit()" :disabled="loading"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> jetzt aktualisieren</button>
        </div>

        <div class="menu text-center mt-3">
          <span @click="$emit('close')">Abbrechen</span>
        </div>
      </div>
      <form ref="payment" v-if="paymentData" :action="paymentData.url" method="post">
        <div class="form-group" v-for="(value, key) in paymentData.data" :key="value">
          <input type="hidden"  v-model="paymentData.data[key]" :name="key" >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeMyContractAlias",
  data(){
    return {
      formData:{
        paymentMethod: "paypal",
      },
      paymentData: null,
      loading: false,
    }
  },
  mounted() {
    this.$gtag.pageview('/Zahlungsdaten');
  },
  methods:{
    submit(){
      this.loading = true;

      this.axios.post("myLogin/paymentInformation", this.formData).then(res => {
        if(res.data.success){
          this.paymentData = res.data.paymentData;
          setTimeout(()=>{
            this.$refs.payment.submit();
          },200);
        }
        else{
          this.$toast.error('Leider ist ein unbekannter Fehler aufgetreten. Versuche es bitte später noch einmal.');
        }
        this.loading = false;
      })
      .catch(error => {
          this.$toast.error('Ein unbekannter Fehler ist aufgetreten!<br><b>Error: </b>' + error.response.status.toString());
          this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
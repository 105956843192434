<template>
  <div id="additionEdit" class="" v-if="contract.isAdditionEditAllowed">

    <div v-if="!termination">
    <h3 class="text-center mb-3" v-if="headline">{{ $t('additions.editAdditions') }}</h3>
      <ul class="dataListing">
        <li v-for="addition in contract.additions" :key="addition.id">
          <a v-if="!addition.endDate" href="#" @click.stop="terminate(addition)" class="float-end link-secondary">{{ $t('additions.terminate') }}</a>
          <b>{{addition.title}}</b>
          <div v-if="addition.subtitle" class="subvalue">
            (<span v-for="(service, index) in addition.subtitle" :key="service">{{service}}<span v-if="index != addition.subtitle.length - 1">, </span></span>)
          </div>
          <span class="highlight" v-if="addition.endDate" style="display:block; line-height: 30px;font-size: 1rem; ">{{ $t('additions.addendumTerminated', {date: getEndDate(addition)}) }}</span>
        </li>
      </ul>

      <div v-if="abortBtn" class="menu text-center mt-3">
        <span @click="$emit('close')">{{ $t('common.abort') }}</span>
      </div>
    </div>
    <addition-edit-termination v-if="termination" @close="termination=null" :addition="termination"></addition-edit-termination>
  </div>
</template>

<script>
import AdditionEditTermination from "@/pages/additions/AdditionEditTermination";
import moment from "moment";

export default {
  name: "AdditionEdit",
  props: ['abortBtn', 'headline'],
  components:{AdditionEditTermination },
  computed: {
    contract () {
      return this.$store.state.contract.contract;
    },
    premiumUpgrade(){
      if(this.additions && this.additions.length == 0) return false;

      return this.additions[0];
    }
  },
  data(){
    return{
      additions: [],
      termination: null,
      images:{
        'Sparpaket': "AIO_neu.jpg",
        'LGF': "lgf.jpg",
        'Nutri': "nutri.jpg",
        'Sun': "solarium.jpg",
        'Drink': "getraenke.jpg",
        'Vibra': "vibra.jpg",
        'Hydro': "HydroMassage.jpg",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    terminate(addition){
      this.termination = addition;
    },
    hasAdditions(){
      if(this.contract.additions && this.contract.additions.length > 0) return true;

      return false;
    },
    getEndDate(addition){
      if(!addition.endDate) return null;
      return moment(addition.endDate).format('DD.MM.YYYY');
    },
    load(){
      this.$contract.fetch();
      this.axios.get('myLogin/additions').then(res => {
        if(res.data.success == true){
          this.additions = res.data.additions;
        }
      });
    },
    getImage(type){
      if(this.images[type]) return "/img/additions/" + this.images[type];

      return null;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="ContractBreak">
    <div class="row">
      <div class="col inner py-3">
        <h3 class="text-center mb-3">{{ $t('contract.pause.headline') }}</h3>

        <p>{{ $t('contract.pause.intro') }}</p>

        <p class="highlight">{{ $t('contract.pause.chooseReason') }}</p>

        <ul class="checkboxlist">
          <li><input name="reason" id="r1" value="Krankheit" type="radio" v-model="formData.reason" required=""><label
              for="r1">{{ $t('contract.pause.reasonIllness') }} <span>({{ $t('contract.pause.certificate') }})</span></label></li>
          <li><input name="reason" id="r2" value="berufsbedingter Ausfall" v-model="formData.reason" type="radio"
                     required=""><label for="r2">{{ $t('contract.pause.reasonWorkRelatedAbsence') }} <span>({{ $t('contract.pause.evidence') }})</span></label></li>
          <li><input name="reason" id="r3" value="Militär" type="radio" v-model="formData.reason" required=""><label
              for="r3">{{ $t('contract.pause.reasonMilitary') }} <span>({{ $t('contract.pause.marchingOrder') }})</span></label></li>
          <li><input name="reason" id="r5" value="andere Gründe" type="radio" v-model="formData.reason"
                     required=""><label for="r5">{{ $t('contract.pause.reasonDifferentReasons') }} <span>({{ $t('contract.pause.certificate') }})</span></label></li>
          <li><input name="reason" id="r6" value="ohne Grund" type="radio" v-model="formData.reason"
                     required=""><label for="r6">{{ $t('contract.pause.reasonWithoutReasons') }} <span>({{ $t('contract.pause.maxMonth') }})</span></label></li>
        </ul>

        <p>
          {{ $t('contract.pause.hint') }}
        </p>

        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label class="form-label">{{ $t('contract.pause.startDate') }}:</label>
              <datepicker class="form-control" ref="dateFrom"  language="de" :lower-limit="minStartDate()" :locale="de"
                          inputFormat="dd.MM.yyyy"
                          v-model="formData.dateFrom"></datepicker>
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label class="form-label">{{ $t('contract.pause.duration') }}:</label>
              <select class="form-select" v-model="formData.dateDuration">
                  <option value="1">1 {{ $t('contract.pause.month') }}</option>
                  <option value="2">2 {{ $t('contract.pause.months') }}</option>
                  <option value="3">3 {{ $t('contract.pause.months') }}</option>
                  <option value="4" v-if="isMonthEnabled()">4 {{ $t('contract.pause.months') }}</option>
                  <option value="5" v-if="isMonthEnabled()">5 {{ $t('contract.pause.months') }}</option>
                  <option value="6" v-if="isMonthEnabled()">6 {{ $t('contract.pause.months') }}</option>
                  <option value="7" v-if="isMonthEnabled()">7 {{ $t('contract.pause.months') }}</option>
                  <option value="8" v-if="isMonthEnabled()">8 {{ $t('contract.pause.months') }}</option>
                  <option value="9" v-if="isMonthEnabled()">9 {{ $t('contract.pause.months') }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="card mb-5" v-if="isNeedUpload()">
          <div class="card-body text-center py-5">
            <input type="file" ref="fileUpload" id="fileUpload" @change="fileChanged()"
                   style="width: 0; height: 0; opacity: 0;">
            <button class="btn btn-secondary" @click="selectFile()">{{ $t('form.selectFile') }}</button>
            <div v-if="formData.files">
              {{ formData.files[0].name }}
            </div>
          </div>
        </div>
        <div class="text-center mt-3">
          <button class="btn btn-primary" @click="submit()" :disabled="!isValid()"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> {{ $t('contract.pause.requestBreak') }}</button>
        </div>
        <div class="menu text-center mt-3">
          <span @click="$emit('close')">{{  $t('common.abort') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Datepicker from 'vue3-datepicker'
import {de} from 'date-fns/locale';
import 'moment/locale/de';
import moment from "moment";


export default {
  setup() {
    return {
      de,
    };
  },
  name: "HomeMyContractBreak",
  components: {
    Datepicker
  },
  computed: {
    today() {
      return new Date;
    }
  },
  watch:{
    'formData.dateFrom' () {
        this.formData.dateTo = this.lowerMin();
    },
    'formData.reason' () {
      if(this.formData.reason == "ohne Grund" && this.formData.dateDuration > 3) {
        this.formData.dateDuration = 3;
      }
    }
  },
  mounted() {
    this.$gtag.pageview('/Vertragspause');
  },
  methods: {
    minStartDate(){
        return moment().startOf('month').toDate();
    },
    lowerMin() {
      if (!this.formData.dateFrom) {
        return moment(this.minStartDate()).add(1, 'month').toDate();
      }

      return moment(this.formData.dateFrom).add(1, 'month').toDate();
    },
    upperMax(){
      if(this.formData.reason != "ohne Grund") return null;

      if (!this.formData.dateFrom) {
        return moment(this.minStartDate()).add(3, 'month').add(-1, 'day').toDate();
      }

      return moment(this.formData.dateFrom).add(3, 'month').add(-1, 'day').toDate();
    },
    isNeedUpload(){
      if(this.formData.reason == null) return false;
      if(this.formData.reason == "ohne Grund") return false;

      return true;
    },
    isMonthEnabled(){
      if(this.formData.reason == null) return false;
      if(this.formData.reason == "ohne Grund") return false;

      return true;
    },
    isValid() {
      if(this.loading) return false;
      if (!this.formData.dateTo) return false;
      //if(!moment(this.formData.dateTo).isValid()) return false;
      if (!this.formData.dateFrom) return false;
      if(!moment(this.formData.dateFrom).isValid()) return false;
      if (!this.formData.reason) return false;

      if(this.isNeedUpload() && !this.formData.files) return false;

      return true;
    },
    submit() {
      let formData = new FormData();


      let dateFrom = moment(this.formData.dateFrom).format("YYYY-MM-DD");
      //let dateTo = moment(this.formData.dateTo).format("YYYY-MM-DD");

      formData.append('reason', this.formData.reason);
      formData.append('dateFrom', dateFrom);
      formData.append('dateDuration', this.formData.dateDuration);

      if(this.isNeedUpload()){
        formData.append('file', this.formData.files[0]);
      }

      this.loading = true;

      this.axios.post("myLogin/contractTimeStop", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.success == true) {
          this.$toast.success(this.$t('contract.pause.success'));
          this.formData = {
            dateFrom: null,
            dateDuration: 1,
            reason: null,
            files: null,
          };
          this.loading = false;
          this.$emit('close');
        } else {
          this.$toast.error(this.$t('error.retry'));
          this.loading = false;
        }
      }).catch(error => {
        this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        this.loading = false;
      });
    },
    selectFile() {
      this.$refs.fileUpload.click();
    },
    fileChanged(e) {
      this.formData.files = this.$refs.fileUpload.files;
    }
  },
  data() {
    return {
      formData: {
        dateFrom: null,
        dateDuration: 1,
        reason: null,
        files: null,
      },
      loading: false,
    }
  }
}
</script>

<style scoped>

</style>
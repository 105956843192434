<template>
  <div id="myContract" v-if="$store.state.contract.contract != false" class="widget h-100 d-flex flex-column" :class="{active: isActive()}">
    <div class="hr">
      <span>{{ $t("contract.headline") }}</span>
    </div>
    <div class="" v-if="!modus">
      <ul class="dataListing">
        <li>
          <div class="field">{{ $t("contract.contract") }}</div>
          <div class="value" v-html="contract.title"></div>

          <p v-if="contract.contractEnd" class=" pt-3">
            <span class="highlight">{{ $t("contract.contractTerminated", {contractEnd: contractEnd, contractAccessEnd: contractAccessEnd}) }}</span><br>
            <i18n-t keypath="contract.contractTerminatedRevoke" tag="span" v-if="contract.isTerminationRevokeAllowed">
              <template v-slot:here>
                  <a href="#" @click="setModus('revoke')">{{ $t('common.here')}}</a>
              </template>
            </i18n-t>
          </p>
        </li>
        <li>
          <div class="field">{{ $t("contract.contractBeginn") }}</div>
          <div class="value">{{contractBegin}}</div>
        </li>
        <li>
          <div class="field">{{ $t("contract.trainingsBeginn") }}</div>
          <div class="value">{{contractAccessBegin}}</div>
        </li>
        <li>
          <div class="field">{{ $t("contract.addition") }}</div>
          <div class="value" v-if="(!contract.additions || contract.additions.length == 0) && contract.isAdditionEditAllowed">{{ $t("contract.noBookedAddition") }} | <router-link :to="{name: 'additions'}">{{ $t("contract.addAddition") }}</router-link></div>
          <div class="value" v-else v-for="addition in contract.additions" :key="addition.id">
              {{addition.title}}
              <div v-if="addition.subtitle" class="subvalue">
                (<span v-for="(service, index) in addition.subtitle" :key="service">{{service}}<span v-if="index != addition.subtitle.length - 1">, </span></span>)
              </div>
          </div>
        </li>
      </ul>
    </div>

    <home-my-contract-alias v-if="modus=='alias'" @close="setModus(false)"></home-my-contract-alias>
    <home-my-contract-break v-if="modus=='break'" @close="setModus(false)"></home-my-contract-break>
    <home-my-contract-end v-if="modus=='end'" @close="setModus(false)"></home-my-contract-end>
    <home-my-contract-revoke v-if="modus=='revoke'" @close="setModus(false)"></home-my-contract-revoke>
    <home-contract-addition v-if="modus=='editAddition'" @close="setModus(false)"></home-contract-addition>

    <div class="menu mt-3" v-if="!isActive()">
      <span @click="$router.push({name: 'additions'})" v-if="!contract.contractEnd && (!contract.additions || contract.additions.length == 0) && contract.isAdditionEditAllowed">{{ $t("contract.bookAddition") }}</span>
      <span @click="setModus('editAddition')" v-else-if="!contract.contractEnd &&  (!contract.additions || contract.additions.length > 0) && contract.isAllInOne && contract.isAdditionEditAllowed">{{ $t("contract.editAddition") }}</span>
      <span @click="$router.push({name: 'additions'})" v-else-if="!contract.contractEnd && !contract.isAllInOne && contract.isAdditionEditAllowed"> {{ $t("contract.upgradeAddition") }}</span>

      <span @click="setModus('alias')" v-if="contract.isAliasAllowed">{{ $t("contract.paymentDetails") }}</span>
      <span @click="setModus('break')">{{ $t("contract.pauseContract") }}</span>
      <span @click="setModus('end')" v-if="!contract.contractEnd && contract.isContractTerminationAllowed">{{ $t("contract.terminateContract") }}</span>
      <span @click="setModus('revoke')" v-if="contract.contractEnd && contract.isTerminationRevokeAllowed">{{ $t("contract.terminateRevokeContract") }}</span>
    </div>
  </div>
</template>

<script>
import 'moment/locale/de';
import moment from "moment";

import HomeMyContractAlias from "@/components/HomeMyContractAlias";
import HomeMyContractBreak from "@/components/HomeMyContractBreak";
import HomeMyContractEnd from "@/components/HomeMyContractEnd";
import HomeMyContractRevoke from "@/components/HomeMyContractRevoke";
import HomeContractAddition from "@/components/HomeContractAddition";


export default {

  name: "HomeMyContract",
  components:{
      HomeMyContractAlias, HomeMyContractEnd, HomeMyContractBreak, HomeMyContractRevoke, HomeContractAddition,
  },
  data(){
    return {
      modus: false,
    };
  },
  mounted() {
      //this.load();
  },
  computed:{
    contract () {
      return this.$store.state.contract.contract;
    },
    contractEnd(){
      if(!this.contract) { return "-"; }

      return moment(this.contract.contractEnd).format('DD.MM.YYYY');
    },
    contractAccessEnd(){
      if(!this.contract) { return "-"; }

      return moment(this.contract.accessEndDate).format('DD.MM.YYYY');
    },

    contractBegin(){
      if(!this.contract) { return "-"; }

      return moment(this.contract.contractBegin).format('DD.MM.YYYY');
    },
    contractAccessBegin(){
      if(!this.contract) { return "-"; }

      return moment(this.contract.accessBeginDate).format('DD.MM.YYYY');
    },
  },
  methods: {
    setModus(modus){
      this.modus = modus;
    },
    isActive(){
      return this.modus != false;
    },
    load(){
      this.axios.get('myLogin/contracts').then(res => {
        this.$store.commit('contract/setContract', res.data.contract);
      });

    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div id="myStudio" class="widget h-100 d-flex flex-column" :class="{active: isActive()}">
    <div class="hr">
      <span>{{ $t('studio.headline') }}</span>
    </div>
    <div v-if="!changeStudio" class="flex-grow-1 d-flex flex-column">
      <ul class="dataListing h-100 d-flex flex-column">
        <li>
          <div class="field">{{ $t('studio.studio') }}</div>
          <div class="value">{{$auth.user().agilea.Studioname}}</div>
        </li>
        <li>
          <div class="field">{{ $t('studio.address') }}</div>
          <div class="value">{{$auth.user().agilea.studioStreet}}, {{$auth.user().agilea.studioZip}}, {{$auth.user().agilea.studioCity}}</div>
        </li>
        <li>
          <div class="field">{{ $t('studio.phone') }}</div>
          <div class="value">{{$auth.user().agilea.studioPhone}}</div>
        </li>
        <li>
          <div class="field">{{ $t('studio.email') }}</div>
          <div class="value">{{$auth.user().agilea.studioMail}}</div>
        </li>
        <li class="">
          <div class="field">{{ $t('studio.bankDetails') }}</div>
          <div class="value" v-if="$auth.user().agilea.Studionummer == 7">
            <strong>Züricher Kantonalbank</strong><br>
            <strong>IBAN:</strong> CH58 0070 0110 0041 4505 6<br>
            <strong>BIC:</strong> ZKBKCHZZ80A<br>
            <strong>CLEARING:</strong> 700
          </div>
          <div class="value" v-else>
            <strong>Credit Suisse AG</strong><br>
            <strong>IBAN:</strong> CH54 0483 5020 5811 3111 1<br>
            <strong>BIC:</strong> CRESCHZZ80A<br>
            <strong>CLEARING:</strong> 4835
          </div>
        </li>
      </ul>
    </div>
    <div v-if="changeStudio">
        <div class="row">
            <div class="col inner py-3">
              <h3 class="text-center mb-3">{{ $t('studio.changeStudio') }}</h3>

              <div class="alert alert-danger my-3" v-if="reason > 0">
                <div v-if="reason == 1">
                  {{ $t('studio.notAllowed1', {lastDate: $filters.date(lastDate), nextDate: $filters.date(nextDate)}) }}
                </div>
                <div v-if="reason == 2">
                  {{ $t('studio.notAllowed2', {lastDate: $filters.date(lastDate), nextDate: $filters.date(nextDate)}) }}
                </div>
              </div>

              <div class="form-floating mb-3">
                <select class="form-select" id="floatingSelect" v-model="formData.studio" aria-label="Floating label select example" >
                  <option v-for="studio in studios" :key="studio.id" :value="studio.externAgileaId">{{studio.name}}</option>
                </select>
                <label for="floatingSelect">{{ $t('studio.chooseStudio') }}</label>
              </div>
              <p class="mb-3 text-primary" style="font-size: 0.7em; font-weight: bold;">
                {{ $t('studio.hint') }}
              </p>

              <div class="text-center">
                <button class="btn btn-primary" @click="submit()" :disabled="!isValid()"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> {{ $t('studio.changeStudio') }}</button>
              </div>

              <div class="menu text-center mt-3">
                <span @click="changeStudio = false">{{ $t('common.abort') }}</span>
              </div>
            </div>
        </div>
    </div>
    <div class="menu mt-3">
        <span v-if="!changeStudio && $auth.user().isStudioChangeAvailable && contract" @click="showStudioChanger">{{ $t('studio.changeStudio') }}</span>
    </div>
  </div>
</template>

<script>



export default {
  name: "HomeMyStudio",
  components:{

  },
  computed:{
    contract() {
      return this.$store.state.contract.contract;
    },
  },
  data(){
      return {
        changeStudio: false,
        studios: [],
        formData: {
          studio: null,
          //notice: '',
        },
        allowed: false,
        reason: 0,
        nextDate: null,
        lastDate: null,
        loading: false,
      }
  },
  mounted() {

  },
  methods: {
    isActive(){
      return this.changeStudio;
    },
    isValid(){
        if(!this.allowed) return false;
        if(this.$auth.user().agilea.studio_id == 7) return false;
        if(this.loading) return false;
        if(!this.formData.studio) return false;

        return true;
    },
    submit(){
      this.loading = true;
      this.axios.post("myLogin/changeStudio", this.formData).then(res => {
        if(res.data.success == true){
          this.$toast.success(this.$t('studio.success'));
          this.$auth.fetch();
          this.$contract.fetch();

          this.changeStudio = false;
          this.loading = false;
        }
        else{
          this.$toast.error(this.$t('error.retry'));
          this.loading = false;
        }
      }).catch(error => {
        this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        this.loading = false;
      });
    },
    showStudioChanger(){
        this.changeStudio = true;
        this.$gtag.pageview('/Studio-wechseln');
        this.loadStudios();
    },
    loadStudios(){
      this.axios.get('myLogin/studios').then(res =>{
          this.studios = res.data.studios;
          this.allowed = res.data.changeAllowed;
          this.reason = res.data.reason;
          this.nextDate = res.data.nextDate;
          this.lastDate = res.data.lastDate;
      });
    }
  }



}
</script>

<style scoped>

</style>
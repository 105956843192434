<template>
  <div class="form-floating mb-3">
    <input type="email" class="form-control text-end" id="Beitrag" placeholder="name@example.com" ref="inputRef">
    <label for="Beitrag">Beitragssumme</label>
  </div>
</template>

<script>
import { watch } from 'vue' // Vue 2: import { watch } from '@vue/composition-api'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  components: {

  },
  props: {
    modelValue: Number, // Vue 2: value
    options: {
      type: Object,
      default(){
        return {
          "currency": "CHF",
            "currencyDisplay": "symbol",
            "precision": 2,
            "hideCurrencySymbolOnFocus": true,
            "hideGroupingSeparatorOnFocus": true,
            "hideNegligibleDecimalDigitsOnFocus": true,
            "autoDecimalDigits": false,
            "useGrouping": true,
            "accountingSign": false
        }
      },
    }
  },
  setup(props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options)

    watch(
        () => props.modelValue, // Vue 2: props.value
        (value) => {
          setValue(value)
        }
    )

    watch(
        () => props.options,
        (options) => {
          setOptions(options)
        }
    )

    return { inputRef }
  }
}
</script>
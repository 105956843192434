<template>
  <main class="h-100 d-flex flex-column" :class="[menu  ? 'menuOpen' : '', hide && !menu ? 'hide' : '']">
      <nav class="navbar navbar-dark fixed-top bg-dark">
        <div class="container">
            <router-link class="navbar-brand" :to="{ name: 'dashboard'}">
              <img src="./assets/puregym_logo.svg" alt="" width="129" height="36">
            </router-link >
            <span v-if="config.debug || admin" class="text-danger d-none d-md-inline" style="font-weight: bold;">{{ config.url }}</span>

            <div>
                <div v-if="$auth.check()" class="dropdown d-inline-block">
                    <span class="dropdown-toggle text-uppercase" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fa-solid fa-globe"></i> {{ $i18n.locale }}
                    </span>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1" style="min-width: 5rem;">
                      <li><a class="dropdown-item" :class="[$i18n.locale == 'de' ? 'active' : '']" href="#" @click="setLocale('de')">DE</a></li>
                      <li><a class="dropdown-item" :class="[$i18n.locale == 'en' ? 'active' : '']" href="#" @click="setLocale('en')">EN</a></li>
                      <li><a class="dropdown-item" :class="[$i18n.locale == 'fr' ? 'active' : '']" href="#" @click="setLocale('fr')">FR</a></li>
                      <li><a class="dropdown-item" :class="[$i18n.locale == 'it' ? 'active' : '']" href="#" @click="setLocale('it')">IT</a></li>
                    </ul>
                </div>
                <button v-if="$auth.check()" @click="menu = !menu" class="navbar-toggler ms-2 collapsed" style="color: white; z-index: 99999;">
                  <i class="fa-solid fa-bars fa-fw" v-if="!menu"></i>
                  <i class="fa-solid fa-xmark fa-fw" v-else></i>
                </button>
            </div>
        </div>
      </nav>
    <div class="bg-light py-2" v-if="$auth.check() && (config.debug || admin)">
        <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-9 col-lg-6 col-xl-6">
                <div class="input-group" v-if="admin">
                  <button class="btn btn-secondary dropdown-toggle" type="button" style="min-width: auto;" data-bs-toggle="dropdown" aria-expanded="false">Historie</button>
                  <ul class="dropdown-menu ">
                    <li v-if="!history.length"><p class="px-3 text-black-50">Keine Einträge vorhanden</p></li>
                    <li><a class="dropdown-item" href="#" v-for="user in history" :key="user.id" @click="login(user.id)">
                      {{ user.id }}
                      <span style="display:block; font-size: 0.75rem;">{{user.agilea.lastName}}, {{user.agilea.firstName}}</span>
                    </a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a class="dropdown-item" href="#" @click="emptyLoginHistory()">Historie leeren</a></li>
                  </ul>

                  <input class="form-control" type="search" placeholder="Mitgliedsnummer" @keyup.enter="login(memberId)" v-model="memberId" aria-label="Search">
                  <button class="btn btn-primary" @click="login(memberId)" type="submit" style="min-width: auto;">Login</button>
                </div>

              </div>
              <div class="col-md-3">
                <button class="btn btn-secondary mt-3 mt-md-0" style="width: 100%" @click="showRawData=true">Agilea Raw-Daten</button>
              </div>
            </div>
        </div>
    </div>
    <router-view class="flex-grow-1" @login="langProp()"></router-view>

      <aside id="menu" :class="{active: showMenu()}">
          <ul>
            <li><router-link :to="{name: 'dashboard'}">{{ $t('menu.myPureGym') }}</router-link></li>
            <li><router-link :to="{name: 'partner'}">{{ $t('menu.partner') }}</router-link></li>
            <li v-if="isGroupFitnessAvailable()"><router-link :to="{name: 'lgf'}">{{ $t('menu.lgf') }}</router-link></li>
            <!--<li v-if="isGroupFitnessAvailable()"><router-link :to="{name: 'lgf2'}">{{ $t('menu.lgf') }} <b>NEU</b></router-link></li>-->
            <li><router-link :to="{name: 'faq'}">{{ $t('menu.faqs') }}</router-link></li>
            <li><router-link :to="{name: 'documents'}">{{ $t('menu.documents') }}</router-link></li>
            <li><router-link :to="{name: 'contact'}">{{ $t('menu.contact') }}</router-link></li>
            <li><a href="#" @click="logout()">{{ $t('menu.logout') }}</a></li>
          </ul>
      </aside>

    <CModal :visible="showRawData" alignment="center" @close="showRawData=false" size="xl">
      <CModalHeader>
        <CModalTitle>Agilea Raw Data</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CNav variant="tabs" role="tablist">
          <CNavItem>
            <CNavLink
                href="javascript:void(0);"
                :active="tabPaneActiveKey === 1"
                @click="() => {tabPaneActiveKey = 1}"
            >
              User
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
                href="javascript:void(0);"
                :active="tabPaneActiveKey === 2"
                @click="() => {tabPaneActiveKey = 2}"
            >
              Contract
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 1">
            <vue-json-pretty :data="$auth.user()"></vue-json-pretty>
          </CTabPane>
          <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 2">
            <vue-json-pretty :data="$store.state.contract.contract" ></vue-json-pretty>
          </CTabPane>
        </CTabContent>

      </CModalBody>
      <CModalFooter class="text-end">
            <button class="btn btn-secondary" @click="showRawData=false">Schließen</button>
      </CModalFooter>
    </CModal>

    <CModal :visible="showPaymentFeedback" alignment="center" @close="showPaymentFeedback=false">
      <CModalHeader>
        <CModalTitle>{{ $t("messages.paypmentUpdate.headline") }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p v-if="paymentFeedback=='accept'">
          <i class="fa-solid fa-thumbs-up fa-2xl text-success" style="margin-right: 3rem;"></i> {{ $t("messages.paypmentUpdate.headline") }}
        </p>
        <p v-else-if="paymentFeedback=='decline'">
          <i class="fa-solid fa-xmark fa-2xl text-danger"></i> {{ $t("messages.paypmentUpdate.decline") }}
        </p>
        <p v-else-if="paymentFeedback=='exception'">
          <i class="fa-solid fa-exclamation fa-2xl text-danger"></i> {{ $t("messages.paypmentUpdate.exception") }}
        </p>
        <p v-else-if="paymentFeedback=='cancel'">
          {{ $t("messages.paypmentUpdate.cancel") }}
        </p>
      </CModalBody>
      <CModalFooter class="text-end">
        <button class="btn btn-primary" @click="showPaymentFeedback=false">{{ $t("common.close") }}</button>
      </CModalFooter>
    </CModal>

    <CModal :visible="showPLanguageSwitcher" alignment="center" @close="showPLanguageSwitcher=false">
      <CModalHeader>
        <CModalTitle>{{ $t('common.language') }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
          <p>
            {{ $t("common.languageQuestion") }}
          </p>
      </CModalBody>
      <CModalFooter class="text-end">
        <button class="btn btn-default" @click="showPLanguageSwitcher=false">{{ $t("common.no") }}</button>
        <button class="btn btn-primary" @click="setCommunicationLanguage()">{{ $t("contract.termination.messages.confirm.confirm") }}</button>
      </CModalFooter>
    </CModal>

  </main>
</template>

<script>
import {
  CModal, CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
    CNav, CNavItem, CNavLink, CTabContent, CTabPane
} from "@coreui/bootstrap-vue";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import axios from "axios";
import {i18n} from "@/main";
import {setI18nLanguage} from "@/i18n";

export default {
  name: 'App',
  components: {
    CModalTitle,
    CModalHeader, CModal, CModalFooter, CModalBody,VueJsonPretty,
    CNav, CNavItem, CNavLink, CTabContent, CTabPane
  },
  watch: {
    '$route' (to, from) {
      this.$gtag.pageview(this.$route);
      this.menu = false;
    },
    '$i18n.locale'(locale){
      axios.defaults.params = {'lang': this.$i18n.locale};
      //Prüfen ob localStorage verfügbar ist
      if(window.localStorage || 0){
        localStorage.setItem('languageSetting', this.$i18n.locale);
      }
    },
    user(oldValue, newValue){
        if(this.user != null && 'id' in this.user) {
          //setI18nLanguage(i18n, this.user.agilea.language);
        }
    },
    menu(){
      if(window.frames && window.frames.lgf != undefined){
        window.frames.lgf.postMessage({menuShown: this.menu, menuHide: this.hide}, "*");
      }
    },
    hide(){
      if(window.frames && window.frames.lgf != undefined){
        window.frames.lgf.postMessage({menuShown: this.menu, menuHide: this.hide}, "*");
      }
    }
  },
  computed:{
      user(){
          return this.$auth.user();
      },
      contract() {
        return this.$store.state.contract.contract;
      },
      admin(){
        return this.$store.state.admin;
      },
      adminToken(){
        return this.$store.state.adminToken;
      },

  },
  data(){
    return {
        config:{
          url: "https://tbc.bitdivision.de/api",
          debug: false,
          staging: false,
        },
        history: [],
        memberId: null,
        menu: false,
        hide: false,
        showRawData: false,
        showPaymentFeedback: false,
        showPLanguageSwitcher: false,
        paymentFeedback: null,
        tabPaneActiveKey: 1,
    };
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  },
  mounted(){
    window.addEventListener('message', this.receiveMessage);

    if(window.myLogin && window.myLogin.config){
      this.config = this.extend(this.config, window.myLogin.config);
    }

    axios.defaults.baseURL = this.config.url;
    axios.defaults.params = {'lang': "de"};

    if(window.localStorage || 0){
      let languageSetting = localStorage.getItem('languageSetting');

        if(languageSetting){
          this.setLocale(languageSetting);
        }
    }

    if(window.sessionStorage || 0){
      let adminToken = sessionStorage.getItem('adminToken');

      if(adminToken){
        this.$store.commit('setAdminStatus', adminToken);
        this.history = JSON.parse(sessionStorage.getItem('loginHistory')) || [];
      }
    }

    this.concardisFeedback();

    let timeout = setInterval(() => {
      if(this.$auth.ready()){
        clearInterval(timeout);
      }

      if(this.$auth.check()){
        this.$contract.fetch();
        //setI18nLanguage(i18n, this.$auth.user().agilea.language);
      }
    }, 300);
  },
  methods:{
    receiveMessage (event) {
      if(event.origin != "https://mypuregym.swiss"){
          return;
      }

      console.log(event.data);

      if(event.data.func == 'toggleMenu'){
        this.menu = !this.menu;
      }
      if(event.data.func == 'closeMenu'){
        this.menu = false;
      }
      if(event.data.func == 'openMenu'){
        this.menu = true;
      }

      if(event.data.func == 'toggleHideMenu'){
        this.hide = !this.hide;
      }

      if(event.data.func == 'hideMenu'){
        this.hide = true;
      }
      if(event.data.func == 'showMenu'){
        this.hide = false;
      }
      if(event.data.func == 'getMenuStatus' || event.data.func == 'getMenuHideStatus'){
        if(window.frames && window.frames.lgf != undefined){
          window.frames.lgf.postMessage({menuShown: this.menu, menuHide: this.hide}, "*");
        }
      }
    },
    langProp(){
        if(this.$auth.user() && this.$i18n.locale != this.$auth.user().agilea.language){
            this.showPLanguageSwitcher = true;
        }
    },
    setLocale(locale){
      setI18nLanguage(i18n, locale);
      this.langProp();
    },
    setCommunicationLanguage(){
      let success = this.$t('data.successLanguageChange');

      this.axios.post( "myLogin/updateLanguage", {language: this.$i18n.locale}).then(res => {
        if(res.data.success == true){

          this.$auth.fetch().then(() => {

          });

          this.$emit('close');
          this.showPLanguageSwitcher = false;
        }
        else{
          let msg = this.$toast.error(this.$t('error.retry'));
          if(res.data.message){
            msg = res.data.message;
          }
          this.$toast.error(msg);
          this.showPLanguageSwitcher = false;
        }
      }).catch(error => {
        this.errors = error.response.data.errors || {};

        if(error.response.status != 422){
          this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        }

        this.showPLanguageSwitcher = false;
      });
    },
    extend(obj1, obj2) {
      let keys = Object.keys(obj2);
      for (let i = 0; i < keys.length; i += 1) {
        let val = obj2[keys[i]];
        obj1[keys[i]] = ['string', 'number', 'array', 'boolean'].indexOf(typeof val) === -1 ? this.extend(obj1[keys[i]] || {}, val) : val;
      }
      return obj1;
    },
    concardisFeedback(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if(urlParams.has('bftype')){
          this.paymentFeedback = urlParams.get('bftype');
          this.showPaymentFeedback = true;
      }
    },
    isGroupFitnessAvailable(){
      if(this.contract == false || this.contract == undefined) return false;

      return (this.contract.isGroupFitnessAvailable || this.contract.isAllStudios);
    },
    isGroupFitnessAllowed(){
      if(this.$store.state.contract.contract == false) return false;

      return this.$store.state.contract.contract.isGroupFitnessAvailable;
    },
    login(memberId){
        if(!this.admin) return false;

        this.axios.post("auth/changeUser", {memberId: memberId, adminToken: this.adminToken}).then(res => {
            let oldUser = this.$auth.user();

            if(res.data.status == "success"){
              this.$auth.token(null, res.data['_token']);

              if(res.data.admin && res.data.admin.admin === true){
                this.$store.commit('setAdminStatus', res.data.admin.adminToken);
              }

              this.$auth.fetch().then(() => {
                this.$toast.success('Login erfolgreich! Sie sind nun als  <strong>' + this.$auth.user().agilea.firstName + " " + this.$auth.user().agilea.lastName + "</strong> eingeloggt.");
                this.$contract.fetch();
              });

              this.history.push(oldUser);
              this.memberId = '';

              if(window.sessionStorage || 0){
                sessionStorage.setItem('loginHistory', JSON.stringify(this.history));
              }

              this.axios.get('myLogin/contracts').then(res => {
                this.$store.commit('contract/setContract', res.data.contract);
              });
            }
            else{
              this.$toast.error('Login ist fehlgeschlagen.');
            }

        }).catch(error => {
          this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        });
    },
    emptyLoginHistory(){
      this.history = [];

      if(window.sessionStorage || 0){
        sessionStorage.removeItem('loginHistory');
      }
    },
    logout(){
      this.$store.commit("unsetAdminStatus");
      this.$auth.logout();
    },
    showMenu(){
      return this.menu && this.$auth.check();
    }
  }
}
</script>

<style>

</style>

<template>

  <!--<button @click="testFunc" :disabled="testDisabled">Test</button>-->

  <div id="dashboard" class="container mt-3" v-if="$auth.user() != null && 'id' in $auth.user() && $auth.user().agilea.type == 7">
      <div class="row justify-content-center">
          <div class="col col-md-8 col-lg-6">
              <div class="alert alert-danger">
                {{ $t('error.inkasso') }}
              </div>
          </div>
      </div>
  </div>
  <div id="dashboard" class="container mt-3" v-else-if="$auth.user() != null && 'id' in $auth.user()">
    <div class="row">
      <div class="col">

      <h1 class="text-center mt-3">{{ $t('dashboard.greetings', {firstname: $auth.user().agilea.firstName})}}</h1>


       <open-due-payment v-if="$auth.user().agilea.type != 7"></open-due-payment>

       <home-addition-h-m-upgrade v-if="isHydromassageUpgradeAvailable()"></home-addition-h-m-upgrade>
       <home-addition-l-g-f-upgrade v-if="isGroupFitnessUpgradeAvailable() && !isHydromassageUpgradeAvailable()"></home-addition-l-g-f-upgrade>

      <div class="row">
          <div class="d-md-block  col-md-6 mb-5" :class="{'d-none': !isMyData()}">
              <home-my-data></home-my-data>
          </div>
        <div class="d-md-block   col-md-6 mb-5" :class="{'d-none': !isMyStudio()}">
          <home-my-studio></home-my-studio>
        </div>
      </div>

      <div class="row">
        <div class="d-md-block  col-md-6 mb-5" :class="{'d-none': !isMyContract()}">
          <home-my-contract></home-my-contract>
        </div>
        <div class="d-md-block  col-md-6 mb-5" :class="{'d-none': !isMyKK()}">
          <home-my-k-k></home-my-k-k>
        </div>
      </div>
      </div>
    </div>
    <div class="row d-md-none ">
        <div class="col">
          <div class="d-grid gap-2 col-10 mx-auto my-5">
            <button class="btn btn-secondary" @click="setModus('myData')" :class="{'d-none': isMyData()}">{{ $t('common.myData') }}</button>
            <button class="btn btn-secondary" @click="setModus('myContract')" :class="{'d-none': isMyContract()}">{{ $t('common.myContract') }}</button>
            <button class="btn btn-secondary" @click="setModus('myStudio')" :class="{'d-none': isMyStudio()}">{{ $t('common.myStudio') }}</button>
            <button class="btn btn-secondary" @click="setModus('myKK')" :class="{'d-none': isMyKK()}">{{ $t('common.kk') }}</button>
            <router-link class="btn btn-secondary" :to="{ name: 'lgf'}" v-if="isGroupFitnessAvailable()">{{ $t('common.lgf') }}</router-link>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import HomeMyData from "@/components/HomeMyData";
import HomeMyStudio from "@/components/HomeMyStudio";
import HomeMyContract from "@/components/HomeMyContract";
import HomeMyKK from "@/components/HomeMyKK";
import HomeAdditionLGFUpgrade from "@/components/HomeAdditionLGFUpgrade";
import HomeAdditionHMUpgrade from "@/components/HomeAdditionHMUpgrade";
import OpenDuePayment from "@/components/OpenDuePayment.vue";

export default {
  name: 'MainDashboard',
  components: {
    OpenDuePayment,
    HomeMyData, HomeMyStudio, HomeMyKK, HomeMyContract, HomeAdditionLGFUpgrade, HomeAdditionHMUpgrade
  },
  computed: {
    contract() {
      return this.$store.state.contract.contract;
    },
  },
  data(){
    return {
      response: null,
      modus: null,
      testDisabled: false,
    }
  },
  methods:{
      setModus(modus){
          this.modus = modus;
          this.scrollToTop();
      },
      scrollToTop() {
        window.scrollTo(0,0);
      },
      isGroupFitnessAvailable(){
        if(this.contract == false || this.contract == undefined) return false;

        return (this.contract.isGroupFitnessAvailable || this.contract.isAllStudios);
      },
      isGroupFitnessUpgradeAvailable(){
        if(this.contract == false || this.contract == undefined) return false;

        return (this.contract.isAllInOneUpgradeAllowed && this.contract.isAdditionEditAllowed);
      },
      isHydromassageUpgradeAvailable(){
        if(this.contract == false || this.contract == undefined) return false;

        return (this.contract.isHydromassageUpgradeAllowed && this.contract.isAdditionEditAllowed);
      },
      isMyData(){
        return this.modus == "myData"
      },
      isMyContract(){
        return this.modus == "myContract"
      },
      isMyStudio(){
        return this.modus == "myStudio"
      },
      isMyKK(){
        return this.modus == "myKK"
      },
      sendData(){
          this.axios.post('login', {test: "Schweinebacke"}).then(res => {
              alert(res.data);
          });
      },
    receiveData(){
        this.axios.get('login/info').then(res => {
          alert(res.data);
          this.response = res.data;
        });
    }
  },
  props: {}
}
</script>


<template>
  <div>
    <h3 class="text-center mb-3">{{ $t('additions.termination.terminateAddition') }}</h3>
    <ul class="dataListing">
      <li>
        <div class="field">{{ $t('additions.termination.yourAddition') }}:</div>
        <div class="value">{{ addition.title }}</div>
      </li>
      <li>
        <div class="field">{{ $t('additions.termination.terminationDate') }}:</div>
        <div class="value">{{ today() }}</div>
      </li>
      <li>
        <div class="field">{{ $t('additions.termination.timeOfTermination') }}</div>
        <div class="value">
          {{ $t('additions.termination.timeOfTerminationText') }}
        </div>
      </li>
    </ul>

    <div class="text-center mt-5">
      <button class="btn btn-primary" @click="openModal()">{{ $t('additions.termination.terminateAddition') }}</button>
    </div>

    <div class="menu text-center mt-3">
      <span @click="$emit('close')">{{ $t('common.abort') }}</span>
    </div>

    <CModal :visible="showHint" alignment="center" @close="showHint=false">
      <CModalHeader>
        <CModalTitle>{{ $t('termination.messages.error.headline') }}</CModalTitle>
      </CModalHeader>
      <CModalBody v-html="$t('termination.messages.error.message', {urlContactForm: $router.resolve({ name: 'contact'}).href})">
      </CModalBody>
      <CModalFooter class="text-end">
        <button class="btn btn-primary" @click="showHint=false">{{ $t('common.close') }}</button>
      </CModalFooter>
    </CModal>

    <CModal :visible="showConfirm" alignment="center" @close="showConfirm=false">
      <CModalHeader>
        <CModalTitle>{{ $t('termination.messages.confirm.headline') }}</CModalTitle>
      </CModalHeader>
      <CModalBody v-html="$t('termination.messages.confirm.message')">
      </CModalBody>
      <CModalFooter class="text-end">
        <button class="btn btn-dark" @click="showConfirm=false" :disabled="loading">{{ $t('termination.messages.confirm.abort') }}</button>
        <button class="btn btn-default" @click="submit()" :disabled="loading"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> {{ $t('termination.messages.confirm.confirm') }}</button>
      </CModalFooter>
    </CModal>

  </div>
</template>

<script>
import {CModal, CModalFooter, CModalBody, CModalHeader, CModalTitle} from '@coreui/bootstrap-vue';
import moment from "moment";

export default {
  name: "AdditionEditTermination",
  components: {
    CModal, CModalFooter, CModalBody, CModalHeader, CModalTitle
  },
  props: ['addition'],
  data() {
    return {
      showConfirm: false,
      showHint: false,
      loading: false,
    }
  },
  mounted() {
    this.$gtag.pageview('/Zusatz-kuendigen');
  },
  methods: {
    openModal(){
      if(this.$store.state.contract.contract.hasFutureContract){
        this.showHint = true;
        return false;
      }
      this.showConfirm = true;
    },
    submit() {
      this.loading = true;
      this.axios.post('myLogin/aboTermination', {addition: this.addition}).then(res => {
        if (res.data.success) {
          this.$toast.success(this.$t('additions.termination.success', {additionName: this.addition.title }));
          this.showConfirm = false;
          this.$emit('close');
          this.loading = false;
          this.$contract.fetch();

          this.$gtag.event('kündigen', {
            'event_category': 'Zusatz',
            'event_label': this.addition.title,
          });
        }
        else
        {
          this.$toast.error(this.$t('error.retry'));
          this.loading = false;
        }
      }).catch(error => {
        this.$toast.error(this.$t('error.unknown', {error: error.response.status.toString()}));
        this.loading = false;
      });
    },
    today() {
      return moment().format('DD.MM.YYYY');
    }
  }
}
</script>

<style scoped>

</style>